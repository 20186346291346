// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktuelles-js": () => import("./../../../src/pages/aktuelles.js" /* webpackChunkName: "component---src-pages-aktuelles-js" */),
  "component---src-pages-anne-ewers-js": () => import("./../../../src/pages/anne-ewers.js" /* webpackChunkName: "component---src-pages-anne-ewers-js" */),
  "component---src-pages-danke-js": () => import("./../../../src/pages/danke.js" /* webpackChunkName: "component---src-pages-danke-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-kurse-js": () => import("./../../../src/pages/kurse.js" /* webpackChunkName: "component---src-pages-kurse-js" */),
  "component---src-pages-linn-ewers-js": () => import("./../../../src/pages/linn-ewers.js" /* webpackChunkName: "component---src-pages-linn-ewers-js" */),
  "component---src-pages-werke-js": () => import("./../../../src/pages/werke.js" /* webpackChunkName: "component---src-pages-werke-js" */)
}

